<template>
	<div class="selecttype" :style="bgIndex">
		<div class="con">
			<div class="top-img">
				<img src="../assets/selecttype/333.png" width="280"/>
			</div>
			<div class="bottom-img">
				<div class="img-hx"><img @click="goHx" class="hximg" :src="imgHx" width="100%" height="100%"/></div>
				<div class="img-ysm"><img @click="goYsm" class="ysmimg" :src="imgYsm"  width="100%" height="100%" /></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				imgYsm:'',
				imgHx:'',
				bgIndex:'',
			}
		},
		methods:{
			goHx(){
				console.log(111);
				 this.$router.push({ path: '/hxmain', query: { product_id: 1 }});
			},
			goYsm(){
				 //this.$router.push("/ysmmain");
				 //this.$router.push({ path: '/ysmmain', query: { product_id: 2 }});
				 this.$alert("产品暂未开放,敬请期待!")
			}
		},
		mounted:function(){
			//1.动态获取瓠犀图片
			this.$axios.get("/getImgUrl", {
				params: {
					type: "imgHx"
				}
			}).then(res => {
				this.imgHx=res.data.data.url
			}).catch(err => {
				console.log(err);
			});
			//2.动态获取优诗美图片
			this.$axios.get("/getImgUrl", {
				params: {
					type: "imgYsm"
				}
			}).then(res => {
				this.imgYsm=res.data.data.url
			}).catch(err => {
				console.log(err);
			});
			//3.动态获取背景图片
			this.$axios.get("/getImgUrl", {
				params: {
					type: "bgIndex"
				}
			}).then(res => {
				this.bgIndex='background-image: url('+res.data.data.url+')'
			}).catch(err => {
				console.log(err);
			});
		}
	}
</script>

<style scoped="scoped">
	.selecttype{
		width: 100% ;
		height: 100%;
		/* background-image: url(../assets/selecttype/selecttypebj.jpg); */
		display: flex;
		justify-content: center;
		align-items: center;
		background-size: 100% 100%;
	}
	
	.top-img{
		/* border: 1px solid red; */
		text-align: center;
		margin-bottom: 3.125rem;
	}
	.bottom-img{
		height: 15rem;
		line-height: 15rem;
		display: flex;
		justify-content: center;
	}
	.hximg,.ysmimg{
		border-radius: 0.9375rem;
		transition: transform .7s;
		box-shadow: 0px 7px 10px rgba(0,0,0,0.4);
	}
	.img-hx,.img-ysm{
		width:450px;
		height:240px;
		line-height: 15rem;
		display: inline-block;
		margin-right: 25px;
	}
	.hximg:hover,.ysmimg:hover{
		
		 cursor: pointer;
		 transform: scale(1.1, 1.1);
	}
	
</style>
